'use strict';

var nesna = nesna || {};

nesna.modal = {
  currentModal: null,
  init(modal) {
    nesna.modal.currentModal = modal;
    $(modal).on('hidden.bs.modal show.bs.modal', function() {
      nesna.modal.reset(this);
    });
    $(modal).on('hidden.bs.modal', function() {
      if(nesna.reloadPage) {
        location.reload();
      }
    });
  },
  toggleLoadingSpinner(display) {
    $(nesna.modal.currentModal).find('button .spinner-border').toggle(display);
  },
  reset(modal) {
    nesna.modal.toggleLoadingSpinner(false);
    let reset = jQuery.Event( "nesna.modal.reset" );
    $(modal).trigger(reset);
  }
}